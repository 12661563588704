<script>
import WikiModal from "../components/Wiki";
import mainStable from "commonsfrontend/src/maker/vue-files/layout/main-stable";
export default {
  extends: mainStable,
  components: { WikiModal }
};
</script>

<style lang="scss">
section.project-info div.page-missions div.mission-warning {
  background: white;
  margin-top: 1em;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid #ffae38;
  svg {
    fill: #ffae38;
    margin-right: 0.2em;
  }
  p {
    display: inline-block;
    margin: 0;
  }
}
.run-style{
  // run button background
  background: #00871e !important;
}
</style>
